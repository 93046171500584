<template>
  <CModalExtended :title="modalTitle" color="dark" size="lg" :close-on-backdrop="false" :show.sync="modalActive">
    <CRow>
      <CCol sm="12">
        <CTabs :active-tab="activeTab" @update:activeTab="handleTab">

          <CTab
            v-for="video in formatedVideos"
            :key="video.ActionMovStowageId"
            :title="video.title"
          >
            <video muted loop="true" controls class="mt-2 mb-2">
              <source :src="video.route" type="video/mp4">
              Tu navegador no soporta el video.
            </video>
          </CTab>

        </CTabs>
      </CCol>
    </CRow>
    <template #footer>
      <CButton shape="square" color="wipe" class="d-flex align-items-center" @click.stop="toggle(false)">
        <CIcon name="x" />
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import ENUM from '@/_store/enum';

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    activeTab: 0,
    videos: [],
  };
}

//Methods
function toggle(newVal) {
  if(this.visit && this.visit != 'restow' && newVal)
    this.getVideos();

  this.modalActive = newVal;
}
function getVideos() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  this.$http
    .get("MovStowageAction-list", {
      MovStowageId: this.getCurrentMovId,
    })
    .then((response) => {
      this.videos = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function getVideo(route) {
  return `${this.$store.getters["connection/getBase"]}${route.replace("Public/", "", null, "i")}`;
}

//Computed
function modalTitle() {
  return `${this.$t("label.help")}: ${this.visit == 'discharge' ? this.$t("label.discharge") : this.$t("label.loadPortSituation")}`;
}
function handleTab(tab) {
  this.activeTab = tab;
}
function getCurrentMovId() {
  switch (this.visit) {
    case 'discharge':
      return 'A13A4AE4-CDAA-4BB1-8CE6-00056BED3A8B';
    case 'load':
      return '8F8EBDB2-0AD1-4ADB-B74A-E4E7E10F469B';
  
    default:
      return '';
  }
}
function formatedVideos() {
  return this.videos.map((item) =>
    Object.assign({}, item, {
      title: this.$i18n.locale == 'en' ? item.ActionMovStowageNameEn : item.ActionMovStowageNameEs,
      route: item.ActionRoute ? `${this.$store.getters["connection/getBase"]}${item.ActionRoute.replace("Public/", "", null, "i")}` : '',
    })
  );
}

export default {
  name: "help-modal",
  mixins: [],
  components: {
  },
  props: {
    modal: Boolean,
    visit: {
      type: String,
      default: "",
    },
    listType: {
      type: Number,
      default: 0,
    },
  },
  data,
  methods: {
    toggle,
    handleTab,
    getVideo,
    getVideos,
  },
  computed: {
    modalTitle,
    getCurrentMovId,
    formatedVideos,
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
      this.activeTab = 0;
    },
  },
};
</script>

<style scoped>
video { 
  width: 100%;
}
</style>